@tailwind base;
@tailwind components;
@tailwind utilities;

.fa{
    font-family:"Arial, FontAwesome";
}

.h-sub{
    height: calc(100vh - theme('height.16'));
}

.bg-orange-600{
    background-color:#f88816;
}

.bg-orange-800{
    background-color:#C66606;
}

.f-b{
    font-weight:100;
}